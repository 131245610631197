<template>
  <div class="newsPage">
    <!-- <div class="orgProjectDropdown">
      <OrganizationSelector />
    </div> -->
    <div class="inputs">
      <div>
        <h1 :style="isMobile ? 'font-size:2em;' : 'font-size: 3em;'">
          {{ $t("News") }}
        </h1>
      </div>
      <div>
        <CButton
          class="modal-confirm text-white"
          @click="openCommunicationModal"
          color="primary"
          data-testid="addCommunicationButton"
          >{{ $t("Add News") }}</CButton
        >
      </div>
    </div>
    <AddEditCommunication
      ref="AddEditCommunication"
      :isCommunicationModalOpen="isCommunicationModalOpen"
      :communicationToEdit="communicationToEdit"
      @close="closeCommunicationModal"
      @add="addCommunication"
      @edit="editCommunication"
      :isEditMode="isEditMode"
      :commId="commId"
      :usersOptions="usersOptions"
      :levelsOptions="levelsOptions"
    />
    <div>
      <ContentLoader
        v-if="loading"
        :speed="2"
        primaryColor="rgb(230, 230, 230)"
        secondaryColor="rgb(240, 240, 240)"
        class="mt-5 p-2"
      ></ContentLoader>
      <div class="" v-else>
        <div class="challenges_container">
          <el-table
            @selection-change="handleSelectionChange"
            :data="communications"
            class="desktop--table news--table org--table"
            style="width: 100%"
          >
            <el-table-column type="selection" width="40" />
            <el-table-column fixed prop="image" :label="$t('Images')">
              <template v-slot="scope">
                <div style="width: 250px; height: 106px">
                  <img
                    :src="getImageSrc(scope.row.image_id)"
                    alt="News Image"
                    style="width: 100%; object-fit: cover"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('Name')" />
            <el-table-column :label="$t('Description')">
              <template v-slot="scope">
                <td>
                  <div>
                    <i
                      class="fa fa-eye blue"
                      v-c-tooltip="{
                        content: getFormattedDescription(scope.row.description),
                        placement: 'bottom',
                      }"
                    ></i>
                  </div>
                </td>
              </template>
            </el-table-column>

            <el-table-column prop="active" :label="$t('News Space')">
              <template v-slot="scope">
                <td>
                  <RaSwitch
                    color="#0071ce"
                    size="sm"
                    :checked="scope.row.active"
                    v-c-tooltip="{
                      content: TurnStatus(scope.row.deadline, scope.row.active),
                      placement: 'top',
                    }"
                    @change="setCommunicationActive(scope.row)"
                  />
                </td>
              </template>
            </el-table-column>
            <el-table-column prop="featured" :label="$t('Home Page')">
              <template v-slot="scope">
                <td>
                  <RaSwitch
                    color="#0071ce"
                    size="sm"
                    :checked="scope.row.featured"
                    v-c-tooltip="{
                      content: TurnStatus(
                        scope.row.deadline,
                        scope.row.featured
                      ),
                      placement: 'top',
                    }"
                    @change="changeFeaturedCommunication(scope.row)"
                  />
                </td>
              </template>
            </el-table-column>
            <el-table-column :label="$t('createdAt')">
              <template v-slot="scope">
                <td>
                  {{ formatDate(scope.row.createdAt) }}
                </td>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="deadline" :label="$t('Deadline')" /> -->
            <el-table-column prop="actions" :label="$t('Actions')">
              <template #header>
                <el-popconfirm
                  @confirm="confirmDeletion()"
                  :title="$t('Are you sure to delete all news ?')"
                >
                  <template #reference>
                    <el-button
                      data-testid="deleteAllCommunicationButton"
                      link
                      size="small"
                      type="danger"
                      :style="
                        communications.length > 0 &&
                        multipleSelection.length ===
                          communications
                            .slice(
                              (currentPage - 1) * perPage,
                              currentPage * perPage
                            )
                            .slice(
                              (currentPage - 1) * perPage,
                              currentPage * perPage
                            ).length
                          ? 'display:unset'
                          : 'display:none'
                      "
                      >{{ $t("Delete all") }}</el-button
                    >
                  </template>
                </el-popconfirm>
                <el-popconfirm
                  @confirm="confirmDeletion()"
                  :title="$t('Are you sure to delete selected news ?')"
                >
                  <template #reference>
                    <el-button
                      link
                      size="small"
                      type="danger"
                      :style="
                        multipleSelection.length &&
                        multipleSelection.length !=
                          communications
                            .slice(
                              (currentPage - 1) * perPage,
                              currentPage * perPage
                            )
                            .slice(
                              (currentPage - 1) * perPage,
                              currentPage * perPage
                            ).length
                          ? 'display:unset'
                          : 'display:none'
                      "
                      >{{ $t("Delete selected") }}</el-button
                    >
                  </template>
                </el-popconfirm>
              </template>
              <template #default="scope">
                <div class="d-flex gap-1 options_actions">
                  <el-button
                    data-testid="editCommunicationButton"
                    size="small"
                    link
                    type="primary"
                    class="action_btn"
                    @click="openEditModal(scope.row)"
                    >{{ $t("Edit") }}</el-button
                  >
                  <el-popconfirm
                    @confirm="confirmDeletion(scope.row.id)"
                    :title="$t('Are you sure to delete selected news ?')"
                  >
                    <template #reference>
                      <el-button
                        data-testid="deleteCommunicationButton"
                        link
                        size="small"
                        type="danger"
                        :class="'action_btn ' + scope.row._id"
                        >{{ $t("Delete") }}</el-button
                      >
                    </template>
                  </el-popconfirm>
                </div>
                <div class="options_actions_mobile">
                  <td>
                    <div style="margin-left: 5px">
                      <span
                        style="cursor: pointer"
                        v-c-tooltip="{
                          content: $t('Edit News'),
                          placement: 'top',
                        }"
                        @click="openEditModal(scope.row)"
                      >
                        <i class="blue fa fa-pencil"></i>
                      </span>
                      <span
                        style="cursor: pointer; margin-left: 10px"
                        v-c-tooltip="{
                          content: $t('Delete News'),
                          placement: 'top',
                        }"
                        @click="openDeleteModal(scope.row)"
                      >
                        <img
                          class="img"
                          :src="require('@/assets/Images/cross.png')"
                          alt="delete"
                        />
                      </span>
                    </div>
                  </td>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="small--screen--table">
            <div
              class="table_container"
              v-for="(item, index) in communications"
              :key="index"
            >
              <div class="d-flex">
                <div class="img_container">
                  <img
                    :src="getImageSrc(item.image_id)"
                    alt="News Image"
                    class="img_mobile"
                  />
                </div>
                <div class="d-flex flex-column ml-3">
                  <label>{{ $t("Name") }}</label>
                  <span>{{ item.comm_name }}</span>
                </div>
              </div>
              <div class="d-flex mt-2 justify-content-between">
                <div class="d-flex flex-column">
                  <label>{{ $t("Deadline") }}</label>
                  <span>{{ item.deadline }}</span>
                </div>
                <div class="d-flex flex-column">
                  <label>{{ $t("Description") }}</label>
                  <i
                    class="text-center fa fa-eye blue"
                    v-c-tooltip="{
                      content: item.description,
                      placement: 'top',
                    }"
                  ></i>
                </div>
                <div class="d-flex flex-column">
                  <label>{{ $t("Active") }}</label>
                  <RaSwitch
                    color="#0071ce"
                    size="sm"
                    :checked="item.active"
                    v-c-tooltip="{
                      content: TurnStatus(item.deadline, item.active),
                      placement: 'top',
                    }"
                    @change="setCommunicationActive(item)"
                  />
                </div>
                <div class="d-flex flex-column">
                  <label>{{ $t("Actions") }}</label>
                  <div style="margin-left: 5px">
                    <span
                      style="cursor: pointer"
                      v-c-tooltip="{
                        content: $t('Edit News'),
                        placement: 'top',
                      }"
                      @click="openEditModal(item)"
                    >
                      <i class="blue fa fa-pencil"></i>
                    </span>
                    <span
                      style="cursor: pointer; margin-left: 10px"
                      v-c-tooltip="{
                        content: $t('Delete News'),
                        placement: 'top',
                      }"
                      @click="openDeleteModal(item)"
                    >
                      <img
                        class="img"
                        :src="require('@/assets/Images/cross.png')"
                        alt="delete"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-container">
            <div>
              <p style="font-size: 13px; margin: 0">
                {{ `${communications.length} ${$t("lines")}` }}
              </p>
            </div>
            <el-pagination
              v-model:current-page="currentPage"
              :page-size="perPage"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
            <div class="d-flex m-0" style="align-items: center">
              <span class="mt-0 mr-2" style="font-size: 13px"
                >{{ $t("Per page") }}:</span
              >
              <CSelect
                style="
                  padding: 0;
                  margin: 0;
                  border: none !important;
                  outline: none !important;
                  box-shadow: none !important;
                  cursor: pointer;
                "
                :options="perOptions"
                @input="changePerPage($event.target.value)"
              >
              </CSelect>
            </div>
          </div>
        </div>
      </div>
      <DeleteCommunicationModal
        :showConfirmDeletion="showConfirmDeletion"
        @close="closeDeleteModal"
        @confirm="confirmDeletion(commId)"
      />
    </div>
  </div>
</template>
<script>
import DeleteCommunicationModal from "./components/DeleteCommunicationModal.vue";
import AddEditCommunication from "./components/AddEditCommunication.vue";
import { CommunicationService } from "@/services/communications";
import { NewsService } from "@/services/news";
import { ContentLoader } from "vue-content-loader";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { mapState } from "vuex";
import { showMessage } from "../../utils/helpers";
import { useGlobalStore } from "../../stores/store";
import { useUserStore } from "@/stores/UserStore";
import { mapWritableState } from "pinia";
import { ElLoading } from "element-plus";

export default {
  name: "Communication",
  components: {
    DeleteCommunicationModal,
    AddEditCommunication,
    ContentLoader,
    OrganizationSelector,
  },
  data() {
    return {
      userStore: useUserStore(),
      showOptions: false,
      loading: false,
      communicationName: "",
      communicationDescription: "",
      dueDate: "",
      showConfirmDeletion: false,
      isCommunicationModalOpen: false,
      communicationToEdit: null,
      commId: null,
      isEditMode: false,
      multipleSelection: [],
      orgOptions: [],
      projectOptions: [],
      communications: [],
      tableFields: [
        {
          key: "image",
          label: "Images",
          _style: "width:300px; text-align: center",
        },
        { key: "comm_name", label: "Name" },
        { key: "description", label: "Description" },
        { key: "active", label: "Active" },
        { key: "deadline", label: "Deadline" },
        { key: "actions", label: "Actions" },
      ],
      tableFieldsFr: [
        {
          key: "image",
          label: "Images",
          _style: "width:300px; text-align: center",
        },
        { key: "comm_name", label: "Nom" },
        { key: "description", label: "Description" },
        { key: "active", label: "Actif" },
        { key: "deadline", label: "Date limite" },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 25,
      perOptions: [25, 50, 100, 200, "All"],
      total: 0,
    };
  },
  created() {
    this.getCommunicationList();
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentOrganizationObj: (state) => state.orgObj,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
    totalPages() {
      return Math.ceil(this.communications.length / this.perPage);
    },
    ...mapWritableState(useGlobalStore, {
      isMobile: "isMobile",
    }),
  },
  watch: {},

  methods: {
    async refreshEverything() {
      this.getCommunicationList();
    },
    getFormattedDescription(description) {
      return description.replace(/<[^>]*>/g, "");
    },
    formatDate(date) {
      if (!date) return null;
      const d = new Date(date);
      const year = d.getFullYear();
      const month = `0${d.getMonth() + 1}`.slice(-2);
      const day = `0${d.getDate()}`.slice(-2);
      return `${day}-${month}-${year}`;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    TurnStatus(deadline, status) {
      if (status) {
        return "Turn Off";
      } else {
        return "Turn On";
      }
    },
    async setCommunicationActive(comm) {
      comm.active = !comm.active;
      const { data, res } = await NewsService.update_news(comm);
      if (data.success) {
        showMessage("success", data.Message);
      } else {
        showMessage("error", data.Message);
      }
    },
    async changeFeaturedCommunication(comm) {
      comm.featured = !comm.featured;
      const { data, res } = await NewsService.update_news(comm);
      if (data.success) {
        showMessage("success", data.Message);
      } else {
        showMessage("error", data.Message);
      }
    },
    getImageSrc(image_id) {
      const myBlobToken = this.userStore.blobToken;
      return `https://stmzgdsengage.blob.core.windows.net/news/${image_id}?${myBlobToken}`;
    },
    async getCommunicationList() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      try {
        // this.loading = true;

        const { data } = await NewsService.get_news({
          page: 1,
          count: 10,
        });
        this.communications = data.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        this.total = data.total;
      } catch (error) {
        console.error("Error fetching News data:", error);
      } finally {
        // this.loading = false;
        loading.close();
      }
    },
    async handleCurrentChange(val) {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      const { data } = await NewsService.get_news({
        page: val,
        count: this.perPage,
      });
      if (data.success) {
        this.total = data.total;
        this.communications = data.data;
      } else {
        showMessage("error", data.Message);
      }
      this.currentPage = val;
      loading.close();
    },

    // changePerPage() {
    //   this.currentPage = 1;
    // },
    async changePerPage(value) {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      const { data } = await NewsService.get_news({
        page: 1,
        count: parseInt(value),
      });
      if (data.success) {
        this.total = data.total;
        this.communications = data.data;
      } else {
        showMessage("error", data.Message);
      }
      loading.close();
      this.perPage = value === "All" ? this.total : parseInt(value);
      this.currentPage = 1;
    },

    openDeleteModal(row) {
      this.commId = row.id;
      this.communicationName = row.comm_name;
      this.showConfirmDeletion = true;
    },
    closeDeleteModal() {
      this.showConfirmDeletion = false;
    },
    async confirmDeletion(rowId) {
      let ids = [];
      if (this.multipleSelection.length) {
        ids = this.multipleSelection.map((news) => news.id);
      } else {
        ids = [rowId];
      }
      const { data, res } = await NewsService.delete_news({
        ids,
      });
      if (res.status === 200) {
        showMessage("success", "News deleted successfully !");
      } else {
        showMessage("error", "News deleted failed !");
      }
      this.closeDeleteModal();
      this.getCommunicationList();
    },
    openCommunicationModal() {
      this.isCommunicationModalOpen = true;
      this.$refs.AddEditCommunication.resetFields();
    },
    closeCommunicationModal() {
      this.isCommunicationModalOpen = false;
      this.communicationToEdit = null;
      this.isEditMode = false;
    },

    async openEditModal(communication) {
      // this.commId = communication._id;
      this.isEditMode = true;
      this.isCommunicationModalOpen = true;
      // const commName = communication.comm_name;
      // const { data, res } =
      //   await CommunicationService.get_communication_details({
      //     _id: communication._id,
      //   });
      this.communicationToEdit = communication;
    },
    addCommunication() {
      this.isEditMode = false;
      this.closeCommunicationModal();
    },
    async editCommunication() {
      this.closeCommunicationModal();
    },
  },
};
</script>
<style>
.news--table.el-table td {
  background: none;
  background-color: none;
}
.news--table .el-table__body-wrapper {
  max-height: calc(100dvh - 380px) !important;
  overflow: auto;
}
@media screen and (max-width: 1280px) {
  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px) !important;
  }
}
</style>
<style scoped>
.small--screen--table {
  display: none;
}
.img_container {
  width: 110px;
  height: 90px;
}
.img_mobile {
  width: 100%;
  height: 100%;
}
.table_container {
  border: 1px solid #c3c3c3;
  border-bottom: none;
  padding: 12px;
}
.el-table.desktop--table th {
  border-bottom: 1px solid #434343 !important;
}
.options_actions_mobile {
  display: none;
}
.action_btn {
  display: none;
}
tr:hover .action_btn {
  display: unset;
}
.tableStyles {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  margin-top: 30px;
}
.inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.communication_container {
  max-height: calc(100vh - 220px);
  overflow-y: auto;
  margin-top: 10px;
}
.news--table td {
  background: unset !important;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  padding: 9px;
  width: 105px;
  margin-left: 10px;
  /* height: 40px; */
}

.blue {
  color: #0071ce !important;
}

.img {
  margin-top: -2px;
  width: 12px;
  height: 12px;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  background-color: #fff;
  width: 100%;
  padding: 15px;

  border: 1px solid #c3c3c3;
  z-index: 1 !important;
}

@media (max-width: 780px) {
  .desktop--table {
    display: none;
  }
  .small--screen--table {
    display: block;
    overflow: auto;
    height: 60vh;
    background-color: white;
  }
}
@media (max-width: 1100px) {
  .options_actions_mobile {
    display: block;
  }
  .options_actions {
    display: none !important;
  }
}
@media (max-width: 970px) {
  .newsPage {
    margin-top: 50px;
  }
}
@media (max-width: 650px) {
  .newsPage {
    margin-top: 0;
  }
}
.orgProjectDropdown {
  width: max-content;
  position: relative;
  bottom: 20px;
  left: 20px;
}

@media (max-width: 1133px) {
  .orgProjectDropdown {
    width: max-content;
    position: relative;
    top: 0;
    left: 20px;
  }
}
.el-table tr,
table tr {
  height: 60px;
  background-color: white;
}

.el-table__inner-wrapper th,
.el-table__inner-wrapper td {
  background: transparent;
}
</style>
